<script setup>
    import { onMounted, ref } from 'vue'

    defineProps({
        modelValue: {
            type: String,
            required: true,
        },
    })

    defineEmits(['update:modelValue'])

    const input = ref(null)

    onMounted(() => {
        if (input.value.hasAttribute('autofocus')) {
            input.value.focus()
        }
    })

    defineExpose({ focus: () => input.value.focus() })
</script>

<template>
    <input
        class="rounded-md border-darkgold-fieldborder shadow-sm focus:border-mintgreen focus:ring-mintgreen"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="input"
        spellcheck="false" />
</template>
